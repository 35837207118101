export default function MenuAdminIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path
        d="M378 261a18 18 0 0 1 10 8c1.8 3.8 2.2 6.8 2.2 11v6.8l-.1 3.6-.1 8.6 3.1.4c3.7.6 6.6 1.5 10 3l3 1.2 8.9 4.4 1.5-1.5 6.7-6.9 2.3-2.4 4.4-4.4a19 19 0 0 1 13.8-4.2c5.1.9 8.9 3.8 12.2 7.6 2 5.3 2.6 9.9 1.1 15.4-4.6 7.9-11.5 14-18 20.4l1.5 3.2 1.9 4.2 1 2.1A67 67 0 0 1 448 357l16.2-.3c11 0 11 0 15.8 3.3a21 21 0 0 1 6.4 13.3c-.5 4.6-1.7 8-4.4 11.7-5 4-8.6 5.1-14.8 5.1h-10.5l-8.7-.1-.5 2.4a82 82 0 0 1-5.7 16.9l-1.6 3.3-1.2 2.4 1.4 1.3 6.4 6.2 2.2 2.1c5.3 5.2 8.3 9.4 9.3 16.9-.6 6.4-3.4 10.3-8.3 14.5a19 19 0 0 1-15 1 61 61 0 0 1-15.3-13.2L415 439l-3.2 1.5-4.2 1.9-2.1 1A67 67 0 0 1 390 448v2.2l.3 9.8v3.4c.1 7-.4 11.7-4.3 17.6a20 20 0 0 1-16 5 21 21 0 0 1-12-9c-1-3.2-1.1-5.6-1.1-9v-10.9l.1-9.1-1.7-.3q-7-1.4-13.6-4.2l-3-1.3-6.7-3.2-1.3 1.3-5.9 6-2 2.1c-5.4 5.4-9.6 9-17.5 9.7a17 17 0 0 1-12.7-5.5c-2.3-3.8-3.9-6.5-4-11v-2.2c1.2-7.4 6.5-11.8 11.6-16.8l7.8-7.6-1.5-3-1.9-4.1-1-2A36 36 0 0 1 299 390h-2.2l-9.8.3h-3.4A28 28 0 0 1 266 386c-4.6-4.9-5.4-9-5.3-15.5.6-5 3.3-7.6 7-10.8 3.7-2.7 5.8-2.8 10.4-2.8h11.4l9.5.1.3-3.1a43 43 0 0 1 3.1-10l1.3-3.1 2.5-5c1-2.4.7-3.3-.2-5.8-1.5-1.8-1.5-1.8-3.3-3.5l-2-2-4.2-4c-4.2-4-7-6.8-7.8-12.7 0-5 .4-8.5 3.3-12.8 4-4 7.6-6.1 13.3-6.2l2.3-.1c8 1 12.7 7.5 18 13.2l1.8 2 1.8 1.9c1.8 1.5 1.8 1.5 4.4 1q4-1.4 7.8-3.2c9.8-4.6 9.8-4.6 15.6-4.6v-2.4l-.2-10.7-.2-3.8c0-11.4 0-11.4 4.4-16.1 5.7-5 9.5-5.6 17-5m-37.8 83.8A46 46 0 0 0 330 380a45 45 0 0 0 18.5 29.5 46 46 0 0 0 35.5 6.4 46 46 0 0 0 26.8-19.8 44 44 0 0 0-17.9-62 45 45 0 0 0-52.7 10.7m-13.6-287a118 118 0 0 1 32.2 52.9l.6 2c8.5 30.4 3.7 62.4-11.2 90q-5.4 9.1-12.2 17.3l-2.3 2.8A118 118 0 0 1 254 262a122 122 0 0 1-89-31.4 113 113 0 0 1-37.3-88.4c.2-32 12.4-60 34.8-82.8 45.5-44.7 117.5-43.3 164-1.6M181.4 87.6C164 107.8 159.4 131 161 157a66 66 0 0 0 4 15l1.1 3.1a87 87 0 0 0 45.8 47.1A85 85 0 0 0 295 213l2.7-2a88 88 0 0 0 31.9-53.8c3-23.6-3-46-16.8-65.2a88 88 0 0 0-56.3-32.6 88 88 0 0 0-75.1 28.2"
        fill="currentColor"
      />
      <path
        d="M241.3 278.6h4a19 19 0 0 1 14.1 7.5A21 21 0 0 1 262 299a20 20 0 0 1-9 12q-5 1.5-10.3 1.4l-4.4.2-2.4.2c-55.7 2.6-122.7 11.7-163.4 54.2a51 51 0 0 0-12.7 37.7L60 431h3l179 .6h2.3c5.3 0 9.3 1.3 13.3 4.9 4 5 4 5 4.4 8.5l.3 3.3c-.4 4.6-1.6 7.9-4.3 11.7-5.8 4.7-10.4 5.1-17.6 5.1H238l-37.9.1-126.3.2c-14 0-26.7-1-37.6-11a43 43 0 0 1-10.5-31.1v-17.2a91 91 0 0 1 27.7-68c46.6-46.5 125.4-58.2 188-59.5"
        fill="currentColor"
      />
    </svg>
  );
}
