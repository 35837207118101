export default function SidebarCollapseIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      role="none"
    >
      <rect
        width="12.75"
        height="12.75"
        x="13.375"
        y="13.375"
        stroke="currentColor"
        strokeWidth="1.25"
        rx="3.375"
        transform="rotate(-180 13.375 13.375)"
      ></rect>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="m10 10-.087-6"
      ></path>
    </svg>
  );
}
