import { Tooltip, UnstyledButton, createStyles } from '@mantine/core';
import { Avatar, Menu, Text } from '@akin/ui-lib';
import { Trans } from '@lingui/macro';
import { getUserImageUrl } from '@akin/core-lib/utils/user';
import { useRouter } from 'next/router';
import { getSpaceProfileImageURL } from '@akin/core-lib/utils/spaceProfile';
import { useState } from 'react';
import LogoutIcon from '@akin/ui-lib/svg/LogoutIcon';
import EditProfileIcon from '@akin/ui-lib/svg/EditProfileIcon';

import PAGE_ROUTES from '@/constants/pageRoutes';
import useAuth from '@/hooks/useAuth';
import useUser from '@/hooks/useUser';
import useSpace from '@/hooks/useSpace';
import DEV_SUPER_ADMIN from '@/constants/devSuperAdmins';
import TopMenuList from './new/TopMenuList';
import FooterMenuList from './new/FooterMenuList';
import HeaderSection from './HeaderSection';

const useStyles = createStyles((theme) => ({
  root: {
    height: '100%',
    width: 200,
    display: 'flex',
    flexDirection: 'column',
    borderRight: '1px solid #f3f4f6',
    backgroundColor: theme.white,
    ...theme.other.sidebarAnimation,

    '&.collapsed': {
      width: 60,
      overflow: 'hidden',
    },

    [theme.fn.smallerThan('xs')]: {
      borderRight: 'unset',
      width: '100%',
    },
  },

  navItemContainer: {
    padding: '0px 10px',
    flex: 1,
    overflow: 'auto',
    ...theme.other.customScrollbar,
    display: 'flex',
    flexDirection: 'column',
  },
  spacer: {
    flex: '1 1',
  },
  profile: {
    width: '100%',
    borderRadius: 10,
    cursor: 'pointer',
    color: theme.white,
  },
  profileDropdown: {
    boxShadow: theme.other.boxShadow.cards,
    [theme.fn.smallerThan('xs')]: {
      '&.mantine-Menu-dropdown': {
        width: '90%!important',
      },
    },

    '& .mantine-Menu-itemLabel': {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
      fontSize: 12,
      color: '#1f2937',
      fontWeight: 500,
    },
  },
  avatarBtn: {
    width: '100%',
    display: 'flex',
    padding: '10px 14px',
    alignItems: 'center',
    gap: 8,

    '&:hover': {
      backgroundColor: '#f3f4f6',
    },

    '&.hideTitle': {
      justifyContent: 'center',
    },
  },
  headerAvatar: {
    height: 48,
    borderBottom: '1px solid #f3f4f6',
    '&.devSuperAdmin': {
      backgroundColor: '#A93A3A',
    },
  },
  footerAvatar: {
    height: 48,
  },
}));

function UserAvatar({
  src = '',
  title = '',
  onClick,
  className,
  lineClamp = 1,
  color = '#1f2937',
  imgSize = 35,
  showTitle = true,
  disableToolTip = false,
}) {
  const { classes, cx } = useStyles();

  return (
    <Tooltip
      label={<Text size="xs">{title}</Text>}
      position="right"
      withArrow
      disabled={disableToolTip}
    >
      <UnstyledButton
        onClick={onClick}
        className={cx(classes.avatarBtn, className, { hideTitle: !showTitle })}
      >
        <Avatar src={src} radius="sm" size={imgSize} />
        {showTitle && (
          <Text
            size={12}
            // lh="12px"
            lineClamp={lineClamp}
            transform="capitalize"
            weight={500}
            color={color}
          >
            {title}
          </Text>
        )}
      </UnstyledButton>
    </Tooltip>
  );
}

export default function SidebarMenu({
  isMobileScreen,
  openPropertySettingsModal,
}) {
  const { cx, classes } = useStyles();
  const router = useRouter();
  const auth = useAuth();
  const { user } = useUser();
  const { currentSpace } = useSpace();
  const userImage = getUserImageUrl(user);
  const [isSidebarCollapsed, setSidebarIsCollapsed] = useState(false);

  const toggleSidebarCollapse = () => {
    setSidebarIsCollapsed((prevState) => !prevState);
  };

  // DEV_SUPER_ADMIN are list of super admin email ids
  // use-case: A DEV_SUPER_ADMIN will have header background color set to red
  const isDevSuperAdmin = DEV_SUPER_ADMIN.includes(user?.email);

  return (
    <div
      className={cx(classes.root, {
        collapsed: isSidebarCollapsed,
      })}
    >
      {!isMobileScreen && (
        <HeaderSection
          isCollapsed={isSidebarCollapsed}
          toggleSidebarCollapse={toggleSidebarCollapse}
        />
      )}

      {/* ================== SPACE PROFILE AVATAR ===================== */}
      {!isMobileScreen && (
        <UserAvatar
          title={currentSpace?.name}
          src={getSpaceProfileImageURL(currentSpace)}
          className={cx(classes.headerAvatar, {
            devSuperAdmin: isDevSuperAdmin,
          })}
          onClick={openPropertySettingsModal}
          lineClamp={1}
          color={isDevSuperAdmin ? '#fff' : '#000'}
          imgSize={23}
          showTitle={!isSidebarCollapsed}
        />
      )}

      <div className={classes.navItemContainer}>
        {/* ================== SIDEBAR TOP MENU LIST  ===================== */}
        <TopMenuList
          isSidebarCollapsed={isSidebarCollapsed}
          toggleSidebarCollapse={toggleSidebarCollapse}
        />

        <div className={classes.spacer} />

        {/* ================== SIDEBAR FOOTER MENU LIST  ===================== */}
        <FooterMenuList
          isSidebarCollapsed={isSidebarCollapsed}
          toggleSidebarCollapse={toggleSidebarCollapse}
        />
      </div>

      {/* ================== USER POPUP MENU ===================== */}
      <Menu position="top" offset={0} width={200} className={classes.profile}>
        <Menu.Target>
          <div>
            <UserAvatar
              src={userImage}
              title={user.full_name}
              className={classes.footerAvatar}
              imgSize={25}
              showTitle={!isSidebarCollapsed}
              disableToolTip
            />
          </div>
        </Menu.Target>

        <Menu.Dropdown className={classes.profileDropdown}>
          <Menu.Item onClick={() => router.push(PAGE_ROUTES.profile)}>
            <EditProfileIcon width={16} height={16} color="#1f2937" />
            <Trans>Edit your profile</Trans>
          </Menu.Item>
          <Menu.Item onClick={auth.logout}>
            <LogoutIcon width={16} height={16} color="#1f2937" />
            <Trans>Logout</Trans>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  );
}
