import { AkinModal as Modal, Text, TextInput } from '@akin/ui-lib';
import { useMediaQuery } from '@mantine/hooks';
import { t, Trans } from '@lingui/macro';
import { createStyles, Pagination } from '@mantine/core';
import SearchIcon from '@akin/ui-lib/svg/SearchIcon';
import { useRouter } from 'next/router';
import useSpace from '@/hooks/useSpace';
import useSpaceSettings from '@/hooks/useSpaceSettings';
import useUser from '@/hooks/useUser';
import { useEffect, useState } from 'react';
import { getLandingPageRoute } from '@/features/permissions/utils';
import { searchSpaces } from '@/services/superadmin';
import { showError } from '@/lib/notifications';
import { getSpaceProfileImageURL } from '@akin/core-lib/utils/spaceProfile';
import PropertyItem from './property-select/PropertyItem';

const useStyles = createStyles((theme) => ({
  body: {
    minHeight: '200px',
    backgroundColor: '#f3f4f6',
  },
  listContainer: {
    display: 'flex',
    gap: 20,
    flexWrap: 'wrap',
  },
  textInput: {
    width: '60%',
    [theme.fn.smallerThan('xs')]: {
      width: '100%',
    },
  },
  footer: {
    justifyContent: 'flex-end',
    borderTop: 'unset',
    minHeight: 65,
    backgroundColor: '#f3f4f6',
  },
}));

const DEFAULT_PAGINATION = {
  total: 1,
  page: 1,
  totalPages: 1,
};

export default function PropertySettingsModal({ opened, onClose }) {
  const isMobileScreen = useMediaQuery('(max-width: 830px)');
  const { classes } = useStyles();
  const router = useRouter();
  const { currentSpaceId, spaces, removeSpace } = useSpace();
  const { switchToSpace } = useSpaceSettings();
  const { user } = useUser();

  const [spaceList, setSpaceList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState(null);
  const [pagination, setPagination] = useState({ ...DEFAULT_PAGINATION });

  const isSuperAdmin = user?.isSuperAdmin;

  useEffect(() => {
    setSpaceList(Object.values(spaces));
  }, [spaces]);

  const switchSpaceHandler = (spaceId) => {
    switchToSpace(spaceId);
    onClose();

    // Determine route to navigate user to depending on user permission on space switch.
    const landingPageRoute = getLandingPageRoute({
      currentSpaceId: spaceId,
      userPermissions: user?.permissions,
    });

    router.replace({
      pathname: landingPageRoute,
    });
  };

  const removeSpaceHandler = (spaceId) => {
    removeSpace({ spaceId });
  };

  const searchSpacesHandler = async (paginationQuery, searchQuery) => {
    try {
      const queryParams = {
        currentPage: paginationQuery.page,
        pageSize: 9,
        searchText: searchQuery.join(','),
      };

      const response = await searchSpaces(queryParams);
      setSpaceList(response.spaces);
      setPagination(response.pagination);
    } catch (error) {
      showError(error.message);
    }
  };

  const onChangeHandler = (e) => {
    const searchQuery = e.target.value;

    if (!searchQuery.trim()) {
      setSpaceList(Object.values(spaces));
      setPagination({ ...DEFAULT_PAGINATION });
      return;
    }

    const searchWords = searchQuery.trim().toLowerCase().split(' ');
    setSearchKeyword(searchWords);

    // Super admin can search and access all spaces.
    if (isSuperAdmin) {
      searchSpacesHandler({ page: 1 }, searchWords);
      return;
    }

    // If user not super admin, avoid API call and search within reducer's spaces list.
    const filteredList = Object.values(spaces).filter((item) => {
      const lowercaseItem = item?.name?.toLowerCase();
      return searchWords.every((word) =>
        lowercaseItem.includes(word.toLowerCase())
      );
    });

    setSpaceList(filteredList);
  };

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      fullScreen={isMobileScreen}
      size={750}
      title={t`Select property`}
    >
      <Modal.Body className={classes.body}>
        <TextInput
          className={classes.textInput}
          rightSection={<SearchIcon width={28} height={28} color="#E7E5E4" />}
          autoComplete="off"
          placeholder={t`Search property`}
          onChange={onChangeHandler}
          mb="xl"
          data-autofocus
        />

        <div className={classes.listContainer}>
          {spaceList.map((space, index) => (
            <PropertyItem
              index={index}
              key={space.space_id}
              title={space?.name}
              src={getSpaceProfileImageURL(space)}
              onClick={() => switchSpaceHandler(space.space_id)}
              selected={currentSpaceId === space.space_id}
              showRemoveOption={
                isSuperAdmin &&
                currentSpaceId !== space.space_id &&
                spaces[space.space_id]
              }
              onRemove={() => removeSpaceHandler(space.space_id)}
            />
          ))}
        </div>

        {spaceList && spaceList.length === 0 && (
          <Text size="20px" ta="center" weight={500} color="#d8d8d8" mt="md">
            <Trans>No properties found</Trans>
          </Text>
        )}
      </Modal.Body>

      <Modal.Footer className={classes.footer}>
        {pagination.totalPages > 1 && (
          <Pagination
            page={pagination.page}
            onChange={(page) => {
              searchSpacesHandler({ page }, searchKeyword);
            }}
            total={pagination.totalPages}
          />
        )}
      </Modal.Footer>
    </Modal>
  );
}
