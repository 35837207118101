import { useRouter } from 'next/router';
import { useMemo } from 'react';

import useSpaceMenu from '@/hooks/useSpaceMenu';
import useSpaceSettings from '@/hooks/useSpaceSettings';
import MenuItem from './MenuItem';
import { checkIfSelectedMenu } from '../helper';

export default function TopMenuList({
  isSidebarCollapsed,
  toggleSidebarCollapse,
}) {
  const router = useRouter();
  const {
    spaceSettings: { isSpaceLoading, currentSpaceId },
  } = useSpaceSettings();
  const { getHeaderMenuList } = useSpaceMenu();

  const navItems = useMemo(() => {
    const menuList = getHeaderMenuList();
    return menuList;
  }, [currentSpaceId, isSpaceLoading]);

  const onClickHandler = (menu) => {
    if (!menu.hasSubMenu) return;

    if (isSidebarCollapsed) toggleSidebarCollapse();
  };

  return navItems.map((menu) => {
    return (
      <MenuItem
        key={menu.id}
        isSelectedMenu={checkIfSelectedMenu({
          menu,
          currentRoute: router.pathname,
          isSidebarCollapsed,
        })}
        expandSubMenu={
          menu.hasSubMenu &&
          menu.subMenu.some((subMenu) => router.pathname === subMenu.route)
        }
        title={menu.name}
        icon={menu.icon}
        href={menu.route}
        query={menu.query}
        isSidebarCollapsed={isSidebarCollapsed}
        onClick={() => onClickHandler(menu)}
      >
        {menu.hasSubMenu &&
          menu.subMenu.map((subMenu) => (
            <MenuItem
              key={subMenu.id}
              isSelectedMenu={checkIfSelectedMenu({
                menu: subMenu,
                currentRoute: router.pathname,
                isSubMenuRoute: true,
                isSidebarCollapsed,
              })}
              title={subMenu.name}
              href={subMenu.route}
              query={subMenu.query}
            />
          ))}
      </MenuItem>
    );
  });
}
