import { Avatar, IconButton, Text, UnstyledButton } from '@akin/ui-lib';
import CrossIcon from '@akin/ui-lib/svg/CrossIcon';

import { createStyles } from '@mantine/core';

const useStyles = createStyles((theme, { avatarColor }) => ({
  root: {
    position: 'relative',
    display: 'flex',
    width: 220,
    borderRadius: 8,
    padding: 5,
    border: `1px solid #ced4da`,
    backgroundColor: theme.white,
    fontSize: 11,
    fontWeight: 600,
    color: '#333333',
    [theme.fn.smallerThan('xs')]: {
      width: '100%',
      fontSize: 12,
    },

    '&.selected': {
      color: theme.colors['theme-color'][7],
      pointerEvents: 'none',
      border: `1px solid ${theme.colors['theme-color'][6]}`,
    },

    '&:hover': {
      backgroundColor: '#dbfbff59',
      color: theme.colors['theme-color'][7],
      border: `1px solid ${theme.colors['theme-color'][6]}`,
    },
  },
  avatar: {
    '& .mantine-Avatar-placeholder': {
      backgroundColor: avatarColor || '#9bc5e8',
      color: '#341f74',
      borderRadius: 'unset',
    },
  },
  crossIcon: {
    position: 'absolute',
    zIndex: 1,
    top: '-9px',
    right: '-9px',
    backgroundColor: '#565656',

    '&:hover': {
      backgroundColor: '#000',
    },
  },
}));

const AVATAR_COLORS = ['#4f874e', '#f0d990', '#a4a2ec', '#ffaf64', '#f26995'];

export default function PropertyItem({
  index = 0,
  onClick,
  selected = false,
  title = '',
  src = '',
  showRemoveOption = false,
  onRemove,
}) {
  const colorIndex = index % AVATAR_COLORS.length;
  const { classes, cx } = useStyles({ avatarColor: AVATAR_COLORS[colorIndex] });

  const onCancelClick = (e) => {
    e.stopPropagation();
    onRemove();
  };

  return (
    <UnstyledButton
      component="div"
      className={cx(classes.root, { selected })}
      onClick={onClick}
    >
      {showRemoveOption && (
        <IconButton
          className={classes.crossIcon}
          size="sm"
          onClick={onCancelClick}
        >
          <CrossIcon width={10} height={10} color="#fff" />
        </IconButton>
      )}

      <Avatar src={src} mr="sm" size={40} className={classes.avatar}>
        {title?.[0]}
      </Avatar>
      <div>
        <Text>{title}</Text>
        {/* <Text size="10px" color="dimmed">
            Dublin
          </Text> */}
      </div>
    </UnstyledButton>
  );
}
