export default function LogoutIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="1.25"
        d="M11.25 13.219c-.055 1.389-1.213 2.568-2.763 2.53-.361-.009-.807-.135-1.699-.386-2.146-.605-4.009-1.623-4.456-3.902-.082-.419-.082-.89-.082-1.833V8.372c0-.943 0-1.414.082-1.833.447-2.28 2.31-3.297 4.456-3.902.892-.252 1.338-.377 1.699-.386 1.55-.038 2.708 1.141 2.763 2.53"
      ></path>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.25"
        d="M15.75 9H7.5m8.25 0c0-.525-1.496-1.506-1.875-1.875M15.75 9c0 .525-1.496 1.506-1.875 1.875"
      ></path>
    </svg>
  );
}
