import { Collapse, Text, UnstyledButton } from '@akin/ui-lib';
import { ChevronIcon, createStyles, Tooltip } from '@mantine/core';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

const useStyles = createStyles(() => ({
  navItem: {
    width: '100%',
    color: '#1f2937',
    display: 'flex',
    padding: '10px 8px',
    justifyContent: 'space-between',
    alignItems: 'center',

    borderRadius: 4,
    marginBottom: 5,

    '&:hover, &.selected': {
      backgroundColor: '#f3f4f6',
    },

    '&.collapsed': {
      justifyContent: 'center',
    },
  },
  itemContent: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    gap: 10,
    color: '#1f2937',
  },
  label: {
    whiteSpace: 'pre-wrap',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: '15px',
  },
  subMenuContainer: {
    borderLeft: '1px solid #dedede',
    marginLeft: 17,
    marginBottom: 10,
    paddingLeft: 10,

    '&.collapsed': {
      display: 'none',
    },
  },
  chevronIcon: {
    color: '#7c7c7c',
    rotate: '-90deg',
    transition: 'rotate 0.3s',
    '&.opened': {
      rotate: '0deg',
    },
  },
}));

export default function MenuItem({
  isSelectedMenu = false,
  icon: Icon,
  title = '',
  href,
  query,
  onClick,
  expandSubMenu = false,
  isSidebarCollapsed = false,
  children,
}) {
  const { cx, classes } = useStyles();
  const router = useRouter();
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(expandSubMenu);
  }, [expandSubMenu]);

  const onClickHandler = () => {
    if (onClick) onClick();

    if (children) {
      setOpened((o) => !o);
      return;
    }

    if (href) {
      router.push({
        pathname: href,
        ...(query?.tabId && {
          query: {
            tabId: query.tabId,
          },
        }),
      });
    }
  };

  return (
    <Tooltip
      label={title}
      fz={12}
      position="right"
      withArrow
      disabled={!isSidebarCollapsed}
    >
      <div>
        <UnstyledButton
          className={cx(classes.navItem, {
            selected: isSelectedMenu,
            collapsed: isSidebarCollapsed,
          })}
          onClick={onClickHandler}
        >
          <div className={classes.itemContent}>
            {/* {icon && <Image src={icon} width={18} height={18} />} */}
            {Icon}

            {!isSidebarCollapsed && (
              <Text className={classes.label}>{title}</Text>
            )}
          </div>

          {!isSidebarCollapsed && children && (
            <ChevronIcon className={cx(classes.chevronIcon, { opened })} />
          )}
        </UnstyledButton>

        {children && (
          <Collapse in={opened}>
            <div
              className={cx(classes.subMenuContainer, {
                collapsed: isSidebarCollapsed,
              })}
            >
              {children}
            </div>
          </Collapse>
        )}
      </div>
    </Tooltip>
  );
}
