import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { MENU_FOOTER_LIST } from '@/constants/menu';
import usePermissions from '@/features/permissions/usePermissions';
import useSpaceSettings from '@/hooks/useSpaceSettings';
import MenuItem from './MenuItem';
import { checkIfSelectedMenu } from '../helper';

export default function FooterMenuList({
  isSidebarCollapsed,
  toggleSidebarCollapse,
}) {
  const router = useRouter();
  const {
    spaceSettings: { isSpaceLoading, currentSpaceId },
  } = useSpaceSettings();
  const { getModuleAccessStatus } = usePermissions();

  const navItems = useMemo(() => {
    const menuList = [];

    MENU_FOOTER_LIST.forEach((menu) => {
      const hasAccess = getModuleAccessStatus(menu.id);

      if (hasAccess) {
        const menuItem = { ...menu };

        if (menu.hasSubMenu) {
          menuItem.subMenu = menu.subMenu.filter((subMenu) =>
            getModuleAccessStatus(subMenu.id)
          );
        }

        menuList.push({ ...menuItem });
      }
    });
    return menuList;
  }, [currentSpaceId, isSpaceLoading]);

  const onClickHandler = (menu) => {
    if (!menu.hasSubMenu) return;

    if (isSidebarCollapsed) toggleSidebarCollapse();
  };

  return navItems.map((menu) => {
    if (menu.component) {
      const CustomNavComponent = menu.component;
      return (
        <CustomNavComponent
          key={menu.id}
          isSelectedMenu={!menu.hasSubMenu && router.asPath === menu.route}
          title={menu.name}
          icon={menu.icon}
          href={menu.route}
          query={menu.query}
          isSidebarCollapsed={isSidebarCollapsed}
        />
      );
    }

    return (
      <MenuItem
        key={menu.id}
        isSelectedMenu={checkIfSelectedMenu({
          menu,
          currentRoute: router.pathname,
          isSidebarCollapsed,
        })}
        expandSubMenu={
          menu.hasSubMenu &&
          menu.subMenu.some((subMenu) => router.pathname === subMenu.route)
        }
        title={menu.name}
        icon={menu.icon}
        href={menu.route}
        query={menu.query}
        isSidebarCollapsed={isSidebarCollapsed}
        onClick={() => onClickHandler(menu)}
      >
        {menu.hasSubMenu &&
          menu.subMenu.map((subMenu) => (
            <MenuItem
              key={subMenu.id}
              isSelectedMenu={checkIfSelectedMenu({
                menu: subMenu,
                currentRoute: router.pathname,
                isSubMenuRoute: true,
                isSidebarCollapsed,
              })}
              title={subMenu.name}
              href={subMenu.route}
              query={subMenu.query}
            />
          ))}
      </MenuItem>
    );
  });
}
