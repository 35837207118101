export default function MenuInboxIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" {...props}>
      <path
        d="M83-.5h348.2a76 76 0 0 1 37.2 8.3l2 1c5.3 3 10.1 6 14.6 10.2l2.7 2.5a78 78 0 0 1 24.8 55v265.7c0 17.1 0 32.6-8.3 48.1l-1 2Q498.6 401 492 408l-2 2.3a74 74 0 0 1-48.2 23.8 269 269 0 0 1-15.4-.6l-15.6-.7-3.3-.2q-21.5-.9-43-1.3-21-.5-41.7-1.5c-65.1-5.1-65.1-5.1-121.6 22.1a265 265 0 0 0-33.8 38.4A52 52 0 0 1 148 508l-2.6 1.3a53 53 0 0 1-35 1.1 53 53 0 0 1-29.2-27.1c-3.9-10.5-3.4-21-3.3-32L78 434h-2a73 73 0 0 1-50-20l-2.3-2a77 77 0 0 1-24-48l-.1-13.4v-62.3L-.5 91.8c0-17.2 0-32.7 8.3-48.2l1-2Q13.2 33.8 19 27l1.8-2.4C36.2 6.4 60-.7 83.1-.5M49 55c-8.2 11.2-9.2 22.8-9.1 36.3v108.2l-.2 145.8c.1 14.8 2.8 26.7 13.2 37.7a44 44 0 0 0 28 11c11.3 1 21.2 4 28.8 13 8.8 12.3 8.8 24.4 8.8 38.9v13.6c0 3.8.3 6.1 2 9.6 2.4 2.2 2.4 2.2 5.7 2.4 5.2-.7 7.2-3 10.7-6.6l2-2 6.3-6.6 16.2-16.5 25.3-25.8c11.3-11.6 21.6-19.9 38.6-20.1h16.5l53.3-.1 126.7-.3c15 0 26.9-.7 38.2-11.5 10.8-11.3 12.2-24.3 12.1-39.3V237l.1-145.2v-3.1c0-14.7-2.7-26.7-13-37.7-12-10.9-26-11.2-41.4-11.1H281.7l-189-.2c-17.2 0-31.6 2-43.7 15.3"
        fill="currentColor"
      />
      <path
        d="M141.3 137.5h20.6l38.8-.1H276a9881 9881 0 0 1 94.4-.1h2.8c7 0 11.4 2.2 16.7 6.7 4.6 5.4 4.7 10.6 4.5 17.5-.8 5.2-2.7 9-6.5 12.5-5.8 4-10.2 4.6-17.3 4.5H350l-32.5.1h-2l-174 .1h-3c-6.9 0-11.3-2.2-16.6-6.7-4.6-5.4-4.7-10.6-4.5-17.5.8-5.2 2.7-9 6.5-12.5 5.8-4 10.2-4.6 17.3-4.5m4.3 118h74l55.1.1h3.3c6.3.8 10 3.9 13.8 8.7 3.8 5.1 4 10.5 3.3 16.7a25 25 0 0 1-11 14 30 30 0 0 1-9 1.2l-26.8.1h-2.5l-73.6.3-28.5.1c-8.8.2-15-.5-21.6-6.7-4.6-5.4-4.7-10.6-4.5-17.5 1-6.7 4-10.6 9.2-14.6 5.8-3.2 12.4-2.4 18.8-2.4m206.1.3 11-.1h3.8q7.9.6 12.8 6.7c4.2 5.2 5.3 9.4 5.1 16-.8 6.9-4.4 11.2-9.6 15.5-5.2 3.5-12.2 2.3-18.3 2.3l-14.8.2c-5.4-.6-9.4-2.6-12.9-6.8a22 22 0 0 1-5-16c.8-6.9 4.4-11.2 9.6-15.5 5.2-3.5 12.2-2.3 18.3-2.3"
        fill="currentColor"
      />
    </svg>
  );
}
