import useUser from '@/hooks/useUser';
import MenuItem from './new/MenuItem';

let hasChatBubbleEle = true;

export default function HelpNavItem(props) {
  const { user } = useUser();

  const onClickHandler = () => {
    if (hasChatBubbleEle) {
      document.querySelector('.akin__chat-popup__popup-container')?.remove();
      document.querySelector('#akin-chat-button')?.remove();
      hasChatBubbleEle = false;
    }

    const akinChatContainerEle = document.querySelector('#akin-chat-container');
    if (akinChatContainerEle) {
      window.cw('open-with-params', {
        skip_registration: true,
        reservation_details: {
          full_name: user.full_name,
          email: user.email,
        },
      });
    }
  };

  return <MenuItem onClick={onClickHandler} {...props} />;
}
