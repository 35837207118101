export default function MenuHelpIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-3 -3 519 519" {...props}>
      <path
        d="m302 3 3 .6a255 255 0 0 1 127.5 66q3.1 2.8 6.5 5.4 1.7 1.7 3.2 3.6l6 6.7c35.4 37.3 56.3 88.2 62.8 138.7q0 1 .3 2 1 8.6.9 17.1v2.9c.3 39.5.3 39.5-3.2 56l-.6 3a258 258 0 0 1-63.9 125.3q-2.6 2.8-5 5.8-2.8 3.4-6.1 6.1l-6.7 6c-37.3 35.3-88.2 56.3-138.7 62.8q-1 0-2 .3-8.5 1-17.2.9H266l-15 .1a231 231 0 0 1-39.9-3.3l-3.4-.6-7.8-1.5-2.8-.6a251 251 0 0 1-115.3-61.8q-2.8-2.6-5.8-5-3.5-2.9-6.2-6.2-3.9-4.6-7.9-8.8A254 254 0 0 1 4.3 307.8l-.7-2.8a236 236 0 0 1-4-51.7 216 216 0 0 1 4-46.4 253 253 0 0 1 66-127.4q2.8-3.1 5.4-6.5 1.7-1.7 3.6-3.2l9.5-8.5C121.5 30.8 165.9 11.3 210 3l2.4-.5A239 239 0 0 1 302 3M99 102l-1.8 1.7A204 204 0 0 0 65 147l-1.2 2A219 219 0 0 0 85 394l1.4 1.7A211 211 0 0 0 147 447l2.2 1.2A218 218 0 0 0 312 469a239 239 0 0 0 102-59.9q7.5-7.6 14-16.1l2.2-2.8c35.5-46 51.2-104 44.2-161.5A224 224 0 0 0 410 99l-1.7-1.7A216 216 0 0 0 356 60l-2.1-1.1c-84-44.3-189-22.4-254.9 43.1"
        fill="currentColor"
      />
      <path
        d="m293 135 2.4 1.1A67 67 0 0 1 312 149l1.8 1.8a49 49 0 0 1 11.4 36.2 66 66 0 0 1-18.2 40l-2 2.3a118 118 0 0 1-17.9 16.6c-9.8 7.8-15.7 15.3-17.3 28q-.6 5.6-1 11.4a22 22 0 0 1-2.4 10.7c-7.5 6.1-16.1 5.7-25.4 5a12 12 0 0 1-8.3-4.6c-6.6-10.6-4.5-27-1.9-38.6 5.3-17.2 15.7-28.4 29.8-39.2 9.4-7.1 17.4-13.6 19.9-25.5.8-5.7.3-10-2.5-15.1a29 29 0 0 0-19.9-10.7c-11.5-1-21.2.5-30.2 7.8a54 54 0 0 0-9.2 11.7c-2.6 3.3-5.1 5-9.3 5.6-7.5 0-12.3-.8-18.2-5.7a22 22 0 0 1-5.5-16.8 45 45 0 0 1 17.7-29c25-18.2 62.2-18.4 89.6-5.9m-30.6 191.8c6.6 5.6 11.8 11.3 12.8 20.2a30 30 0 0 1-7.4 22 29 29 0 0 1-22.6 7.4 28 28 0 0 1-18.8-11.2A29 29 0 0 1 223 342a29 29 0 0 1 12.8-16c8.7-4 18.2-3.6 26.6.8"
        fill="currentColor"
      />
    </svg>
  );
}
