import { MobileMenu } from '@akin/ui-lib';
import { Burger, createStyles } from '@mantine/core';
import { useEffect, useState } from 'react';
import MobileSpaceMenuButton from './MobileSpaceMenuButton';
import SidebarMenu from '../sidebar';
import useURLParams from '../../../../hooks/useURLParams';
import useUser from '../../../../hooks/useUser';

const useStyles = createStyles((theme) => ({
  propertyModal: {
    backgroundColor: '#F5F4F4',
  },
  header: {
    backgroundColor: theme.white,
    backgroundImage: 'linear-gradient(to right, #70C195, #ECC1D8, #4E89BF)',
  },
  adminHeader: {
    backgroundColor: 'rgb(200, 60, 60)',
    backgroundImage: 'unset',
  },
}));

export default function MobileHeader({ openPropertySettingsModal }) {
  const { classes } = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { router } = useURLParams();
  const { user } = useUser();
  const isSuperAdmin = user?.isSuperAdmin;

  useEffect(() => {
    setIsMenuOpen(false);
  }, [router.asPath]);

  const toggleMenu = () => {
    setIsMenuOpen((prevState) => !prevState);
  };

  return (
    <MobileMenu>
      <MobileMenu.Header
        className={isSuperAdmin ? classes.adminHeader : classes.header}
      >
        <MobileSpaceMenuButton
          onClick={openPropertySettingsModal}
          isSuperAdmin={isSuperAdmin}
        />
        <Burger
          opened={isMenuOpen}
          onClick={toggleMenu}
          transitionDuration={0}
          color={isSuperAdmin ? '#fff' : '#000'}
        />
      </MobileMenu.Header>

      {/* ----------------MOBILE MENU BODY--------------- */}
      {isMenuOpen && (
        <MobileMenu.Body>
          <SidebarMenu isMobileScreen />
        </MobileMenu.Body>
      )}
    </MobileMenu>
  );
}
