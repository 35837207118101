import { Title } from '@akin/ui-lib';
import { createStyles } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import PageTitle from './PageTitle';
import Sidebar from './components/sidebar';
import PropertySettingsModal from '../settings/PropertySettingsModal';
import useModalStates from '../../hooks/useModalStates';
import MobileHeader from './components/header/MobileHeader';

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    [theme.fn.smallerThan('xs')]: {
      height: '100dvh',
    },
  },
  header: {
    overflow: 'hidden',
    boxShadow: theme.other.boxShadow.cards,
    [theme.fn.smallerThan('xs')]: {
      // borderRadius: 0,
      marginBottom: 'unset',
    },
  },
  main: {
    flex: 1,
    display: 'flex',
    overflowY: 'hidden',
  },
  content: {
    // width: 'calc(100vw - 200px)',
    flex: 1,
    overflowY: 'auto',
    ...theme.other.customScrollbar,

    [theme.fn.smallerThan('xs')]: {
      borderRadius: 0,
      // width: '100%',
      // padding: 0,
      paddingTop: 50,
    },
  },
  mainContainer: {
    // padding: '20px 30px',
    // [theme.fn.smallerThan('xs')]: {
    //   padding: 10,
    // },
    // border: '1px solid red',
  },
}));

export function ContentTitle({ pageName, children, titleProps }) {
  return (
    <>
      <PageTitle pageName={pageName} />
      <Title order={4} {...titleProps}>
        {children}
      </Title>
    </>
  );
}

export function MainContainer({ children, className }) {
  const { classes, cx } = useStyles();
  return <div className={cx(classes.mainContainer, className)}>{children}</div>;
}

export default function MainLayout({ children }) {
  const { classes } = useStyles();
  const isMobileScreen = useMediaQuery('(max-width: 575px)');
  const {
    isModalOpen: isPropertySettingsModalOpen,
    openModal: openPropertySettingsModal,
    closeModal: closePropertySettingsModal,
  } = useModalStates();

  return (
    <div className={classes.root}>
      {/* --------------- MOBILE HEADER------------------ */}
      {isMobileScreen && (
        <div className={classes.header}>
          <MobileHeader openPropertySettingsModal={openPropertySettingsModal} />
        </div>
      )}

      {/* --------------- MAIN BODY------------------ */}
      <div className={classes.main}>
        {/* --------------- SIDEBAR------------------ */}
        {!isMobileScreen && (
          <Sidebar openPropertySettingsModal={openPropertySettingsModal} />
        )}

        {/* --------------- Content Section------------------ */}
        <div className={classes.content}>{children}</div>
      </div>

      {/* ----------------PROPERTY SETTINGS MODAL--------------- */}
      {isPropertySettingsModalOpen && (
        <PropertySettingsModal
          opened={isPropertySettingsModalOpen}
          onClose={closePropertySettingsModal}
        />
      )}
    </div>
  );
}
