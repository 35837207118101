export default function EditProfileIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="17 30 470 460" {...props}>
      <path
        fill="currentColor"
        d="m276 40 3.6 1a121 121 0 0 1 70.6 58.3c15 28.3 17 61.9 7.6 92.3a122 122 0 0 1-61.3 69.7 123 123 0 0 1-92.8 4 122 122 0 0 1-67.5-64.9 122 122 0 0 1-3.6-82.5A112 112 0 0 1 156 77l1.9-2.3c27.4-33 77.4-46.1 118.1-34.7m-96.5 60.3A80 80 0 0 0 170 115l-1.5 2.7a87 87 0 0 0-4.9 61.6 91 91 0 0 0 46.1 52.5 88 88 0 0 0 66.4 2 103 103 0 0 0 10.8-5.3 86 86 0 0 0 40.5-50.6c6-20.7 3.5-43.8-6.4-62.9a111 111 0 0 0-17-22l-2-2c-17.2-16.6-39.6-21.6-62.6-21.3-23.6.7-44.4 13.5-60 30.6m65 189.4h3.6a442 442 0 0 1 45.4 1.9c7.4.9 13.7 2.3 19 8 3.2 4.8 3.2 9.8 2.6 15.4q-3.6 7.4-11 11a98 98 0 0 1-18.8-.7 352 352 0 0 0-39.8-1.6H242a312 312 0 0 0-54.8 4c-37 6.1-74.3 16.7-104.2 40.3l-3 2.4A54 54 0 0 0 60 403q-.5 8.2-.3 16.6l.1 15c-.3 3.5-.3 3.5 1.2 6.4l8.8.1h12.4l52.6.2h2l145.7.3h2.7c5.7 0 10.1.9 14.4 5 4.8 6.2 5.1 10.6 4.4 18.4-2.3 5.2-5.8 8-11 10-3.3.6-6.5.5-9.8.5H75.6c-16 0-28.2-.7-40.3-12-7.8-8.4-9.4-18.7-9.5-29.6v-2.7l-.1-14.2c-.2-27 8-48.3 27-67.9C89.8 312.5 147 298 197 292l2.2-.3c15-1.7 30-2 45.2-2m183.2-13 22 10.5 2.4 1 4.4 2A40 40 0 0 1 478 315c3.3 15.6-2.7 28.2-9.4 42l-18.4 39-1.5 3.4-19.4 41.2-1.7 3.4c-7.1 13.2-20 17.7-33.2 23.2l-9.4 4-2.3 1-13.8 6-3 1.4-5.6 2.5c-7 3.1-13.6 5-21.1 2.8-6.4-3.8-9-8.8-11-15.8l-.8-2.3-4.2-13.5-6.5-21.7c-7.7-25.1-5.4-39.6 6-63l5.1-10.7 13-27.5 1.5-3.3 13-28.2c6.2-13.6 12.3-25.1 26.7-30.9 16.4-5.8 31 1.6 45.6 8.7M392 300.6a37 37 0 0 0-5.4 9.2l-1 2-10.6 23-18.8 40.4-10.3 22c-3.5 7-.6 14.6 1.6 21.7l.7 2.4L356 447q7.1-2.7 14.1-5.7 5.7-2.2 11.6-4c10.1-3.2 16.5-7.2 21.5-16.7q4.4-9.1 8.1-18.5 3.8-9.2 8.3-18.2l3.1-6.8 11.6-24.9 7.5-15.8 1.7-3.5 1.5-3a10 10 0 0 0 0-7.9c-2.6-1.9-2.6-1.9-6-3.4L403.6 302l-1.9-1c-5-2.4-5-2.4-9.8-.4"
      />
    </svg>
  );
}
