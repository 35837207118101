import { Text, UnstyledButton } from '@akin/ui-lib';
import { createStyles } from '@mantine/core';
import SidebarCollapseIcon from '@akin/ui-lib/svg/SidebarCollapseIcon';
import LOGOS from '@akin/core-lib/logos';

const useStyles = createStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 10px',
    height: 48,
    borderBottom: '1px solid #f3f4f6',

    '&.collapsed': {
      justifyContent: 'center',

      '& .collapseBtn': {
        display: 'none',
      },
    },

    '&.collapsed:hover': {
      '& .logo': {
        display: 'none',
      },

      '& .collapseBtn': {
        display: 'unset',
      },
    },
  },
  logoSection: {
    display: 'flex',
    alignItems: 'center',

    '& .logo': {
      width: 32,
      height: 32,
      borderRadius: 4,
    },
  },
  collapseBtn: {
    height: 18,
    color: '#858e96',
    '&:hover': {
      color: theme.black,
    },
  },
}));

export default function HeaderSection({ isCollapsed, toggleSidebarCollapse }) {
  const { classes, cx } = useStyles();
  return (
    <div className={cx(classes.root, { collapsed: isCollapsed })}>
      <div className={classes.logoSection}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img alt="d3x" className="logo" src={LOGOS.d3xLogo} />

        {!isCollapsed && (
          <Text weight={700} ml={10}>
            D3x
          </Text>
        )}
      </div>
      <UnstyledButton
        ml={0}
        onClick={toggleSidebarCollapse}
        className={cx(classes.collapseBtn, 'collapseBtn')}
      >
        <SidebarCollapseIcon />
      </UnstyledButton>
    </div>
  );
}
