// eslint-disable-next-line import/prefer-default-export
export function checkIfSelectedMenu({
  menu,
  currentRoute,
  isSubMenuRoute = false,
  isSidebarCollapsed = false,
}) {
  if (isSidebarCollapsed && isSubMenuRoute) return false;

  const isMenuSelected = currentRoute === menu.route;

  if (isMenuSelected) return true;

  if (isSidebarCollapsed && !isMenuSelected && menu.hasSubMenu) {
    const isSubMenuSelecetd =
      menu.hasSubMenu &&
      menu.subMenu.some((subMenu) => currentRoute === subMenu.route);

    if (isSubMenuSelecetd) return true;
  }

  return false;
}
